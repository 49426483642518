import React, { useCallback, useState } from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import Modal from "../../components/Modal/modal";
import { Text } from "../../components/Text/Text";
import { isMobile } from "../../hooks/checkMobile";
import banner from "../../images/academyBanner.png";
import { color, spacing, typography } from "../../shared/style";
import { ModalContact } from "../ContactContainer/ModalContact";
import {redirectPage} from "../../hooks/redirect";

export const Banner = ({
  den_logo,
  banner,
  title,
  description,
  button_label,
  button_link
}) => {
  const [openContact, setOpenContact] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact);
  }, [openContact, setOpenContact]);
  return (
    <WrapperSection banner={banner}>
      <WrapperModal>
        <Modal open={openContact} handleOpenModal={handleOpenModal}>
          <ModalContact
            handleOpenModal={handleOpenModal}
            data="admin@draperstartuphouse.com"
          />
        </Modal>
      </WrapperModal>
      <Content>
        <ContainerLogo>
          <Logo src={den_logo} alt="logoBanner"></Logo>
        </ContainerLogo>
        <TextExtend>
          <Fade distance="10px" bottom duration={2000}>
            <Text
              font={typography.type.code}
              tag="h3"
              color={color.primary.white}
              capital="uppercase"
              weight={typography.weight.regular1}
              lineHeight={56}
              align="center"
              size={typography.size.l3}
            >
              {title}
            </Text>
          </Fade>
        </TextExtend>
        <Description
          color={color.primary.white}
          weight={typography.weight.bold}
          align="center"
          font={typography.type.primary}
          lineHeight={26}
        >
          {description}
        </Description>
        <Fade distance="10px" delay={500} disabled={isMobile}>
          <GroupButton>
            <ButtonExtended 
              type="primary" 
              onClick={() => 
                redirectPage({
                  url: button_link?.url,
                  type: button_link?.type,
                  link_type: button_link?.link_type
                })
              }
            >
              {button_label}
            </ButtonExtended>
          </GroupButton>
        </Fade>
      </Content>
    </WrapperSection>
  );
};

const Logo = styled.img``;
const ContainerLogo = styled.div`
  margin-bottom: 28px;
  margin-top: 100px;
  max-width: 183px;
`;

const GroupButton = styled.div`
  margin-top: 30px;
  margin-bottom: 120px;
`;
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  padding: 20px 45px;
  text-transform: capitalize;
`;
const TextExtend = styled.div`
  letter-spacing: -0.015em;
  max-width: 410px;
  @media (max-width: 1024px) and (min-width: 769px) {
    width: 90%;
  }
  @media (max-width: 650px) {
    width: auto;
  }
  span {
    @media (max-width: 1024px) and (min-width: 651px) {
      padding: 20px 0;
      font-size: 62px !important;
      line-height: 62px;
    }
    @media (max-width: 650px) {
      font-size: 36px !important;
      line-height: 36px;
    }
  }
`;
const Description = styled(Text)`
  text-align: center;
  width: 320px;
  margin-top: 24px;
  @media (max-width: 1024px) and (min-width: 769px) {
    width: 50%;
  }
  @media (max-width: 1024px) and (min-width: 650px) {
    width: 70%;
    margin-top: 26px;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin-top: 16px;
  }
`;
const WrapperSection = styled.div`
  height: 700px;
  box-sizing: border-box;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  @media (max-width: 650px) {
    display: flex;
    justify-content: center;
    min-height: 646px;
  }
`;
const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 1100px;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  padding-top: 30px;
  box-sizing: border-box;
  @media (max-width: 1024px) and (min-width: 650px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    margin-top: 70px;
    padding-top: 0;
    align-items: center;
    justify-content: center;
  }
`;
