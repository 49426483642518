import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography, spacing } from "../../shared/style";
import { Button } from "../../components/Button/Button";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

export const UpComming = ({ data }) => {
  const { primary } = data || {};
  const redirectLink = (url) => {
    window.location.href = `${url}`;
  };
  if (!primary) return null;
  return (
    <WrapperSection>
      <WrapperSectionTop>
        <WrapperContent>
          <BoxTitle
            tag="h3"
            color={color.primary.blue}
            size={typography.size.code}
            lineHeight={typography.size.code}
          >
            <RichText
              render={primary.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </BoxTitle>
        </WrapperContent>
        <WrapperGeneral
          font={typography.type.primary}
          weight={typography.weight.regular}
          size={typography.size.s3}
          lineHeight={26}
          align="center"
          color={color.netraul.black80}
        >
          <RichText
            render={primary.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </WrapperGeneral>
        <ButtonExtended
          type="primary"
          onClick={() => redirectLink("https://www.eventbrite.com/")}
        >
          See all upcoming events
        </ButtonExtended>
      </WrapperSectionTop>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  padding: 140px 131px 0;
  margin: auto;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding-bottom: 66px;
  justify-content: center;
  flex-direction: column;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) {
    padding: 0 40px;
    padding-top: 100px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
    padding-top: 100px;
  }
  @media (max-width: 650px) {
    margin-top: 90px;
    padding: 0 20px;
  }
  @media (max-width: 376px) {
    margin-top: 90px;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  width: 276px;
  color: #2a2a2a;
  background: ${color.primary.white};
  border: 1px solid ${color.primary.blue};
  white-space: nowrap;
  padding: 7px 35px;
  &:hover {
    color: ${color.primary.white};
    background: ${color.primary.blue};
    border: 1px solid ${color.primary.blue};
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 826px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 650px) {
    display: none;
    width: 335px;
    margin: 10px auto 0px;
  }
`;
const WrapperSectionTop = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 60px;
  grid-template-columns: auto auto auto;
  padding-bottom: 50px;
  border-bottom: 10px solid ${color.primary.blue10};
  @media (max-width: 1377px) {
    grid-gap: 30px;
  }
  @media (max-width: 1300px) and (min-width: 650px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 649px) {
    grid-template-columns: auto;
    padding-bottom: 0px;
  }
`;
const WrapperContent = styled.div`
  @media (max-width: 649px) {
    width: 100%;
  }
`;
const WrapperGeneral = styled.div`
  width: 90%;
  line-height: 26px;
  color: ${color.netraul.black80};
  @media (max-width: 1200px) {
    padding-left: 20px;
  }
  @media (max-width: 650px) {
    padding-left: 0;
    padding-bottom: 24px;
    width: 100%;
  }
`;
const BoxTitle = styled(Text)`
  margin-bottom: 32px;
  font-weight: ${typography.weight.regular1};
  line-height: 90px;
  white-space: nowrap;
  font-family: ${typography.type.code};
  font-size: ${typography.size.code}px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 72px !important;
    line-height: 72px;
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
