import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color, typography, spacing } from "../../shared/style";
import { Button } from "../../components/Button/Button";
import useWindowSize from "../AcademyDetailContainer/useWindowSize";
import { redirectPage } from '../../hooks/redirect';

export const Events = ({ data }) => {
  const [width] = useWindowSize();
  const [listShow, setListShow] = useState([]);

  const redirectLink = (url) => {
    // window.location.href = `${url}`;
    typeof window !== "undefined" && window.open(url, '_blank');
  };

  useEffect(() => {
    if (width < 650) {
      setListShow([data[0]]);
    } else {
      setListShow(data);
    }
  }, [width, data]);
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        {listShow?.map((item) => (
          <Wrapper>
            <WrapperContent>
              <Image src={item.node?.logo?.original?.url} />
              <WrapBodyItem>
                <WrapBodyItemInfo>
                  <Date>{item?.node?.start?.local}</Date>
                  <Description>{item?.node?.summary}</Description>
                </WrapBodyItemInfo>
                <WrapButton>
                  <ButtonExtended
                    type="tertiary"
                    onClick={() => 
                      // redirectLink(`${item?.node?.url}`)
                      redirectPage({
                        url: item?.node?.url,
                        type: "",
                        link_type: "Web"
                      })
                  }
                  >
                    Register
                  </ButtonExtended>
                </WrapButton>
              </WrapBodyItem>
            </WrapperContent>
          </Wrapper>
        ))}
        <ButtonExtendedMobile
          type="primary"
          onClick={() => redirectLink("https://www.eventbrite.com/")}
        >
          See all upcoming events
        </ButtonExtendedMobile>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapButton = styled.div``;
const WrapBodyItemInfo = styled.div``;
const WrapBodyItem = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;
const WrapperSection = styled.div`
  max-width: 1500px;
  padding: 0 131px;
  box-sizing: border-box;
  margin: auto;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) {
    margin-top: 60px;
    padding: 0 40px;
    margin-bottom: 100px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
    margin-top: 60px;
  }

  @media (max-width: 650px) {
    padding: 0 20px;
    margin-top: 46px;
    margin-bottom: 80px;
  }
`;
const ButtonExtendedMobile = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  display: none;
  height: 65px;
  width: 276px;
  color: #2a2a2a;
  background: ${color.primary.white};
  border: 1px solid ${color.primary.blue};
  white-space: nowrap;
  padding: 7px 35px;
  text-transform: inherit;
  margin: auto;
  &:hover {
    color: ${color.primary.white};
    background: ${color.primary.blue};
    border: 1px solid ${color.primary.blue};
  }
  @media (max-width: 650px) {
    display: block;
    width: 335px;
  }
`;
const WrapperGeneral = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
  box-sizing: border-box;
  padding-bottom: 60px;
  align-items: stretch;
  @media (max-width: 1200px) {
    padding-bottom: 0px;
  }
  @media (max-width: 650px) {
    padding-bottom: 0px;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  flex: 0 0 33.333%;
  max-width: 33.333%;
  box-sizing: border-box;
  margin-bottom: 40px;
  padding: 0 14px;
  padding-bottom: 20px;
  transform: translateY(0);
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 55px rgb(0 0 0 / 22%);
    transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
      box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  }
  @media (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
  @media (max-width: 375px) {
    padding: 0;
    &:hover {
      transition: initial;
      transform: initial;
      box-shadow: none;
    }
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;

  @media (max-width: 376px) {
    min-width: fit-content;
  }
`;
const Image = styled.img`
  max-height: 390px;
  width: 100%;
  @media (max-width: 649px) {
  }
`;
const Date = styled.div`
  padding: 24px 0 16px 0;
  letter-spacing: 0.2em;
  font-weight: ${typography.weight.bold};
  line-height: 22px;
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s3}px;
  color: ${color.primary.blue};
  text-transform: uppercase;
  @media (max-width: 649px) {
    padding: 18px 0 8px 0;
  }
`;
const Description = styled.div`
  white-space: pre-wrap;
  letter-spacing: -0.015em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-weight: ${typography.weight.regular1};
  line-height: 36px;
  font-family: ${typography.type.code};
  font-size: ${typography.size.l1}px;
  text-transform: uppercase;
  color: ${color.netraul.black100};
  @media (max-width: 376px) {
    line-height: 36px;
    font-size: 30px;
  }
`;
const ButtonExtended = styled(Button)`
  margin-top: 20px;
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 40px;
  white-space: nowrap;
  padding: 7px 35px;
  text-transform: capitalize;
`;
