import { RichText } from "prismic-reactjs";
import React, { useCallback, useState } from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import Modal from "../../components/Modal/modal";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import { redirectPage } from "../../hooks/redirect";
import { color, spacing, typography } from "../../shared/style";
import { ModalContact } from "../ContactContainer/ModalContact";

export const Benefit = ({ data }) => {
  const { primary, items } = data || {};
  const [openContact, setOpenContact] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact);
  }, [openContact, setOpenContact]);
  if (!primary || !items) return null;
  return (
    <WrapperSection>
      <WrapperModal>
        <Modal open={openContact} handleOpenModal={handleOpenModal}>
          <ModalContact
            handleOpenModal={handleOpenModal}
            data="admin@draperstartuphouse.com"
          />
        </Modal>
      </WrapperModal>
      <Title
        font={typography.type.code}
        tag="h3"
        weight={typography.weight.regular1}
        lineHeight={90}
        align="center"
        color={color.primary.blue}
        capital="uppercase"
      >
        <RichText
          render={primary?.title?.raw}
          htmlSerializer={htmlSerializer}
        />
      </Title>
      {items &&
        items.map((item, i) => (
          <WrapperSectionTop key={i} position={item.image_position}>
            <WrapperGeneral position={item.image_position}>
              <Fade distance="30px" left duration={1000}>
                <Image
                  position={item.image_position}
                  colorShadow={item?.shadow_color}
                  src={item?.media_box_image?.url}
                />
              </Fade>
            </WrapperGeneral>
            <WrapperContentRight position={item?.image_position === "right"}>
              <TextTitle
                tag="h3"
                weight={typography.weight.regular1}
                lineHeight={36}
                font={typography.type.code}
                size={typography.size.l1}
                color={color.netraul.black100}
                capital="uppercase"
              >
                {item?.media_box_title?.text}
              </TextTitle>
              <Description
                weight={typography.weight.regular}
                lineHeight={26}
                font={typography.type.primary}
                size={typography.size.s3}
                color={color.netraul.black70}
              >
                <RichText
                  render={item?.media_box_description?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Description>
            </WrapperContentRight>
          </WrapperSectionTop>
        ))}
      <WrapperSectionBottom>
        <ButtonExtended onClick={() =>
          redirectPage({
            url: primary?.button_link?.url,
            type: primary?.button_link?.type,
            link_type: primary?.button_link?.link_type
          })
        }>
          {primary?.button_label?.text}
        </ButtonExtended>
      </WrapperSectionBottom>
    </WrapperSection>
  );
};
const WrapperSectionBottom = styled.div`
  margin-top: -71px;
  margin-bottom: 130px;
  @media (max-width: 1200px) {
    margin-top: -6px;
    margin-bottom: 99px;
  }
  @media (max-width: 650px) {
    margin-top: -20px;
    margin-bottom: 79px;
  }
`;
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`;
const WrapperSection = styled.div`
  display: flex;
  max-width: 1500px;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 150px;
  background-color: ${color.primary.blue05};
  @media (max-width: 1024px) and (min-width: 651px) {
    padding-top: 100px;
  }
  @media (max-width: 649px) {
    padding-top: 80px;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  padding: 20px 45px;
  text-transform: capitalize;
`;
const WrapperSectionTop = styled.div`
  display: flex;
  margin-bottom: 204px;
  flex-direction: ${(props) =>
    props.position === "left" ? "row" : "row-reverse"};
  @media (max-width: 1200px) {
    margin-bottom: 120px;
  }

  @media (max-width: 992px) {
    margin-bottom: 100px;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;
const WrapperContentRight = styled.div`
  width: 52%;
  box-sizing: border-box;
  ${(props) =>
    props.position === "left"
      ? `padding-left: 127px; padding-right: 130px;`
      : `padding-left: 130px; padding-right: 210px`};

  @media (max-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding: 0 20px;
    margin-top: 67px;
  }
`;
const WrapperGeneral = styled.div`
  width: 48%;
  box-sizing: border-box;
  ${(props) =>
    props.position === "left" ? `padding-left:41px;` : `padding-right:40px; `};
  @media (max-width: 650px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const Title = styled(Text)`
  margin-bottom: 100px;
  width: 80%;
  letter-spacing: -0.015em;
  @media (max-width: 1024px) and (min-width: 650px) {
    margin-bottom: 80px;
    line-height: 90px;
  }
  @media (max-width: 992px) {
    line-height: 62px;
  }
  @media (max-width: 649px) {
    font-size: 62px !important;
    margin-bottom: 60px;
    line-height: 62px;
  }
`;
const Description = styled(Text)`
  margin-bottom: 4%;
  ul {
    margin-top: 24px;
    li {
      margin-top: 16px;
      font-size: 14px;
      line-height: 22px;
      color: #232327;
    }
  }
  &:last-child {
    color: ${color.netraul.black100};
  }
`;
const Image = styled.img`
  width: 100%;
  height: 438px;
  object-fit: cover;
  ${(props) =>
    props.position === "left"
      ? `box-shadow: -41px 54px 1px ${props.colorShadow};`
      : `box-shadow: 41px 54px 1px ${props.colorShadow}`};

  @media (max-width: 992px) {
    ${(props) =>
      props.position === "left"
        ? `box-shadow: -41px 54px 1px ${props.colorShadow};`
        : `box-shadow: 41px 54px 1px ${props.colorShadow}`};
  }
  @media (max-width: 650px) {
    height: 214px;
    ${(props) =>
      props.position === "left"
        ? `box-shadow: -20px 26px 1px ${props.colorShadow};`
        : `box-shadow: 20px 26px 1px ${props.colorShadow}`};
  }
`;
const TextTitle = styled(Text)`
  display: block;
  margin-bottom: 32px;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
  }
  @media (max-width: 376px) {
    margin-bottom: 24px;
  }
`;
