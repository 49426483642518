import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import { ItemAuthorList } from "./AuthorContainer/ItemAuthorList";

export const About = ({ data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperSectionTop>
        <WrapperContent>
          <BoxTitle
            tag="h3"
            weight={typography.weight.regular1}
            lineHeight={90}
            capital="uppercase"
            color={color.primary.blue}
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </BoxTitle>
        </WrapperContent>
      </WrapperSectionTop>
      {items && <ItemAuthorList items={items} />}
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  padding: 130px 131px;
  margin: auto;
  background-color: ${color.primary.blue05};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 100px 40px;
  }
  @media (max-width: 992px) {
    padding: 100px 30px;
  }
  @media (max-width: 650px) {
    padding: 80px 20px;
  }
  @media (min-width: 756px) {
    .slick-slider .slick-arrow {
      display: block !important;
    }
  }
`;

const WrapperSectionTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperContent = styled.div`
  width: 60%;
  max-width: 550px;
  @media (max-width: 1200px) {
    max-width: 450px;
    width: auto;
  }

  @media (max-width: 650px) {
    width: 100%;
    max-width: 400px;
  }
`;

const BoxTitle = styled(Text)`
  margin-bottom: 32px;
  letter-spacing: -0.015em;
  font-weight: ${typography.weight.regular1};
  line-height: 90px;
  font-family: ${typography.type.code};
  font-size: ${typography.size.code}px;
  text-transform: uppercase;
  @media (max-width: 1200px) {
    margin-bottom: 30px;
    font-size: 72px !important;
    line-height: 72px;
  }

  @media (max-width: 650px) {
    width: 100%;
    line-height: 62px;
    font-size: 62px !important;
  }
`;
