import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";

export const HowItWork = ({ data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <Title
        font={typography.type.code}
        tag="h3"
        weight={typography.weight.regular1}
        lineHeight={90}
        align="center"
        color={color.primary.blue}
        capital="uppercase"
      >
        <RichText
          render={primary?.title?.raw}
          htmlSerializer={htmlSerializer}
        />
      </Title>
      <WrapperSectionContent>
        <WrapperGeneral>
          <List>
            {items?.map((item, i) => {
              return (
                <ListItem key={i}>
                  <HeaderItem>
                    <WrapIcon>
                      <Icon src={item?.box_icon?.url} />
                    </WrapIcon>
                    <Rank
                      weight={typography.weight.regular1}
                      size={typography.size.l3}
                      lineHeight={56}
                      font={typography.type.code}
                    >
                      {`0${i + 1}`}
                    </Rank>
                  </HeaderItem>
                  <Description
                    size={typography.size.m1}
                    weight={typography.weight.bold}
                    lineHeight={28}
                    font={typography.type.primary}
                    color={color.netraul.black100}
                  >
                    {item?.description?.text}
                  </Description>
                </ListItem>
              );
            })}
          </List>
        </WrapperGeneral>
      </WrapperSectionContent>
    </WrapperSection>
  );
};

const WrapIcon = styled.div`
  max-width: 40px;
  min-width: 40px;
`;
const WrapperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1500px;
  box-sizing: border-box;
  margin: auto;
  padding: 0 131px;
  padding-top: 130px;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) {
    padding: 0 40px;
    padding-top: 100px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
    padding-top: 60px;
  }
  @media (max-width: 649px) {
    padding: 0 20px;
    padding-top: 55px;
  }
  @media (max-width: 376px) {
    padding-bottom: 100px;
    padding-top: 80px;
  }
`;
const WrapperSectionContent = styled.div`
  width: 100%;
  padding-bottom: 90px;
  @media (max-width: 649px) {
    flex-direction: column;
  }
  @media (max-width: 376px) {
    padding-bottom: 0px;
  }
`;
const WrapperGeneral = styled.div`
  margin: 0 auto;
`;
const Title = styled(Text)`
  span {
    font-weight: 500;
  }
  width: 100%;
  margin-bottom: 50px;
  text-align: left;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
    margin-bottom: 40px;
  }
  @media (max-width: 649px) {
    margin-bottom: 40px;
  }
`;
const Description = styled(Text)`
  margin: 0 36px 33px 44px;
  display: inline-block;
  @media (max-width: 769px) {
    margin-left: 30px;
  }
`;
const List = styled.ul`
  display: grid;
  grid-gap: 5%;
  grid-template-columns: auto auto auto auto;
  list-style: none;
  @media (max-width: 1024px) and (min-width: 650px) {
    grid-gap: 3%;
    grid-row-gap: 5%;
    grid-template-columns: auto auto;
  }
  @media (max-width: 769px) {
    grid-gap: 20px;
    grid-template-columns: auto;
  }
`;
const ListItem = styled.li`
  height: auto;
  background-color: ${color.primary.blue05};
  width: 100%;
  @media (max-width: 769px) {
    margin: 0 auto;
  }
  @media (max-width: 650px) {
    margin: 0 auto;
  }
`;
const Icon = styled.img`
  padding-top: 25px;
  width: 100%;
`;
const Rank = styled(Text)`
  color: #1729401a;
`;
const HeaderItem = styled(Text)`
  justify-content: space-between;
  display: flex;
  padding: 19px 20px 32px 44px;
  @media (max-width: 769px) {
    padding: 19px 20px 20px 30px;
  }
`;
