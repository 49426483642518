import React from 'react'
import { ThemeProvider } from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Sidebar } from '../components/Sidebar';
import { DenContainer } from '../containers/DenContainer';
import FooterContainer from '../containers/FooterContainer';
import { Header } from '../containers/HeaderContainer';
import useApp, { withAppContext } from '../contexts/context'
import { useSiteMetadata } from '../hooks/useQuerydata';
import { useQueryDen } from '../hooks/useQueryDen';

const Den = () => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const { allPrismicDen, allEventbriteEvent } = useQueryDen();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={allPrismicDen.nodes[0].data?.body4} />
        }
      >
        <DenContainer
          data={allPrismicDen.nodes[0].data}
          dataEvents={allEventbriteEvent.edges}
        />
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(Den);
