import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography, spacing } from "../../shared/style";
import { Button } from "../../components/Button/Button";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import Modal from "../../components/Modal/modal";
import { ModalContact } from "../ContactContainer/ModalContact";
import { redirectPage } from "../../hooks/redirect";


export const BannerBottom = ({ title, button_label, button_link }) => {
  const [openContact, setOpenContact] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact);
  }, [openContact, setOpenContact]);
  if (!title || !button_label) return null;
  return (
    <WrapperSection>
      <WrapperModal>
        <Modal open={openContact} handleOpenModal={handleOpenModal}>
          <ModalContact
            handleOpenModal={handleOpenModal}
            data="admin@draperstartuphouse.com"
          />
        </Modal>
      </WrapperModal>
      <WrapperSectionTop>
        <WrapperContent>
          <BoxTitle tag="h3" color={color.primary.white}>
            <RichText render={title?.raw} htmlSerializer={htmlSerializer} />
          </BoxTitle>
          <ContainerButton>
            <ButtonExtended 
            type="tertiary" 
            onClick={() => 
              redirectPage({
                url: button_link?.url,
                type: button_link?.type,
                link_type: button_link?.link_type
              })
            }>
              {button_label?.text}
            </ButtonExtended>
          </ContainerButton>
          {/* <ButtonExtendedMobile type="tertiary" onClick={handleOpenModal}>
            Become our service partner
          </ButtonExtendedMobile> */}
        </WrapperContent>
      </WrapperSectionTop>
    </WrapperSection>
  );
};
const ContainerButton = styled.div``;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  background-color: ${color.primary.white};
`;
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;

  margin: 0 auto;
  padding: 12px 45px;
  display: block;
  @media (max-width: 650px) {
    margin: 0 auto;
  }
  @media (max-width: 376px) {
    /* display: none; */
  }
`;
// const ButtonExtendedMobile = styled(Button)`
//   border-radius: ${spacing.borderRadius.Larger}px;
//   height: 65px;
//   width: 156px;
//   margin: 0 auto;
//   padding: 12px 45px;
//   display: none;
//   @media (max-width: 376px) {
//     display: block;
//     width: 298px;
//   }
// `;
const WrapperSectionTop = styled.div`
  justify-content: space-between;

  @media (max-width: 649px) {
    flex-direction: column;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  padding: 80px;
  flex-direction: column;
  background-color: ${color.primary.blue};
  @media (max-width: 650px) {
    padding: 70px 20px;
  }
`;
const BoxTitle = styled(Text)`
  width: 715px;
  letter-spacing: -0.015em;
  margin: auto;
  text-align: center;
  font-weight: ${typography.weight.regular1};
  line-height: 56px;
  font-family: ${typography.type.code};
  font-size: ${typography.size.l3}px;
  text-transform: uppercase;
  margin-bottom: 40px;
  span {
    color: ${color.primary.white}!important;
    font-weight: 500;
  }
  @media (max-width: 1024px) and (min-width: 650px) {
    width: 55%;
    line-height: 45px;
    font-size: 39px !important;
  }
  @media (max-width: 650px) {
    width: 80%;
    line-height: 45px;
    font-size: 40px !important;
  }
  @media (max-width: 376px) {
    width: 100%;
    line-height: 36px;
    font-size: 36px !important;
  }
`;
