import { useStaticQuery, graphql } from "gatsby"

export const useQueryDen = () => {
  const { allPrismicDen, allEventbriteEvent } = useStaticQuery(
    graphql`
      query QueryDen {
        allPrismicDen {
          nodes {
            data {
              banner {
                url
                alt
              }
              banner_title {
                html
                text
                raw
              }
              banner_description {
                html
                text
                raw
              }
              banner_button_label {
                html
                text
                raw
              }
              banner_button_link {
                url
                link_type
                type
              }
              den_logo {
                url
                alt
              }
              body {
                ... on PrismicDenBodyMission {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    button_label {
                      text
                    }
                    button_link {
                      url
                      link_type
                      type
                    }
                  }
                  items {
                    media_box_title {
                      html
                      text
                      raw
                    }
                    media_box_description {
                      html
                      text
                      raw
                    }
                    media_box_image {
                      url
                      alt
                    }
                    image_position
                    shadow_color
                  }
                }
              }
              body1 {
                ... on PrismicDenBody1HowDenWorks {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    box_icon {
                      url
                      alt
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body2 {
                ... on PrismicDenBody2Ecosystem {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    button_label {
                      text
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                  }
                }
              }
              body3 {
                ... on PrismicDenBody3Reviews {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    avatar {
                      url
                      alt
                    }
                    name {
                      text
                    }
                    posittion {
                      text
                    }
                    comment {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              sub_mission_description {
                html
                text
                raw
              }
              body5 {
                ... on PrismicDenBody5UpcomingEvents {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body4 {
                ... on PrismicDenBody4GeneralCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    robot {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    googlebot {
                      html
                      text
                      raw
                    }
                    favicon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    bingbot {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicDenBody4OpenGraph {
                  primary {
                    og_url {
                      url
                      type
                      link_type
                    }
                    og_type {
                      html
                      text
                      raw
                    }
                    og_title {
                      html
                      text
                      raw
                    }
                    og_site_name {
                      html
                      text
                      raw
                    }
                    og_locale {
                      html
                      text
                      raw
                    }
                    og_image_width {
                      html
                      text
                      raw
                    }
                    og_image_height {
                      html
                      text
                      raw
                    }
                    og_image {
                      alt
                      url
                    }
                    og_description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicDenBody4TwitterCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicDenBody4PinterestProductPin {
                  slice_type
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price
                    currency
                    availability
                  }
                }
              }
              blurb {
                html
                text
                raw
              }
              blurb_button_label {
                text
              }
              blurb_button_link {
                url
                link_type
                type
              }
            }
          }
        }
        allEventbriteEvent(limit: 6) {
          edges {
            node {
            summary
            url
            logo {
              original {
                url
              }
            }
            start {
              timezone
              local(formatString: "DD MMM YYYY")
              utc(formatString: "DD MMM YYYY")
            }
          }
        }
        totalCount
      }
      }
      `
  )

  return {
    allPrismicDen,
    allEventbriteEvent
  }
}