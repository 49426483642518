import { RichText } from "prismic-reactjs";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Cycle } from "../../components/Cycle/Cycle";
import Modal from "../../components/Modal/modal";
import { Text } from "../../components/Text/Text";
import { color, spacing, typography } from "../../shared/style";
import { ModalContact } from "../ContactContainer/ModalContact";
import { redirectPage } from "../../hooks/redirect";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";

export const Comunity = ({ data }) => {
  const { primary } = data || {};
  const [openContact, setOpenContact] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact);
  }, [openContact, setOpenContact]);
  if (!primary) return null;
  return (
    <WrapperSection>
      <WrapperModal>
        <Modal open={openContact} handleOpenModal={handleOpenModal}>
          <ModalContact
            handleOpenModal={handleOpenModal}
            data="admin@draperstartuphouse.com"
          />
        </Modal>
      </WrapperModal>
      <WrapperSectionTop>
        <WrapperContent>
          <BoxTitle tag="h3" color={color.primary.blue}>
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </BoxTitle>
          <WrapperGeneralTablet>
            <Description
              weight={typography.weight.regular}
              lineHeight={26}
              font={typography.type.primary}
              size={typography.size.s3}
              color={color.netraul.black80}
            >
              {primary?.description?.text}
            </Description>
            <ButtonContainer>
              <ButtonExtended 
                type="primary" 
                onClick={()=> 
                  redirectPage({
                    url: primary?.button_link?.url,
                    type: primary?.button_link?.type,
                    link_type: primary?.button_link?.link_type
                  })
                }
              >
                {primary?.button_label?.text}
              </ButtonExtended>
            </ButtonContainer>
          </WrapperGeneralTablet>
        </WrapperContent>
        <WrapperGeneral>
          {/* <ComunityTitle src={comunityTitle} /> */}
          {/* <Image src={imgComunity} /> */}
          <Cycle />
        </WrapperGeneral>
        <WrapperGeneralMobile>
          <Description
            weight={typography.weight.normal}
            lineHeight={26}
            font={typography.type.primary}
            size={typography.size.s3}
            color={color.netraul.black80}
          >
            {primary?.description?.text}
          </Description>
          <ButtonContainer>
            <ButtonExtended 
            type="primary" 
            onClick={()=>
              redirectPage({
                url: primary?.button_link?.url,
                type: primary?.button_link?.type,
                link_type: primary?.button_link?.link_type
              })
            }
            >
              {primary?.button_label?.text}
            </ButtonExtended>
          </ButtonContainer>
        </WrapperGeneralMobile>
      </WrapperSectionTop>
    </WrapperSection>
  );
};

const ButtonContainer = styled.div`
  @media (max-width: 650px) {
    text-align: center;
  }
`;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 0 131px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 649px) {
    padding-top: 24px;
    margin-bottom: 80px;
    padding: 0 20px;
  }
  @media (max-width: 376px) {
    padding-top: 0;
    margin-bottom: 0px;
    padding: 0 20px;
  }
`;
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  padding: 20px 45px;
  text-transform: capitalize;
  @media (max-width: 376px) {
    margin-bottom: 100px;
  }
`;
const WrapperSectionTop = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding-bottom: 170px;
  @media (max-width: 1200px) {
    padding-bottom: 200px;
    width: 100%;
    grid-template-columns: auto;
  }
  @media (max-width: 850px) {
    padding-bottom: 130px;
  }
  @media (max-width: 650px) {
    padding-bottom: 20px;
  }
  @media (max-width: 376px) {
    padding-bottom: 80px;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  @media (max-width: 1024px) and (min-width: 650px) {
    padding-top: 5%;
  }
  @media (max-width: 649px) {
    width: 100%;
    padding-top: 0px;
  }
`;
const WrapperGeneral = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  @media (max-width: 1200px) and (min-width: 650px) {
    width: 80%;
    margin: 0 auto;
  }
  @media (max-width: 649px) {
    width: 100%;
    margin-bottom: 10%;
  }

  @media (max-width: 376px) {
    margin-top: -24px;

    width: 100%;
    margin-bottom: 24px;
  }
  svg {
    height: auto !important;
  }
`;
const WrapperGeneralMobile = styled.div`
  display: none;
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    span {
      width: 100%;
    }
    button {
      margin: 0 auto;
    }
  }
`;
const WrapperGeneralTablet = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    display: none;
  }
`;
const Description = styled(Text)`
  width: 85%;
  margin-bottom: 32px;
  @media (max-width: 813px) {
    width: 85%;
    margin-top: 40px;
  }
`;
// const Image = styled.img`
//   margin: 0 auto;
//   height: auto;
//   object-fit: cover;
// `;

const BoxTitle = styled(Text)`
  width: 70%;
  margin-bottom: 32px;
  letter-spacing: -0.015em;
  font-weight: ${typography.weight.regular1};
  line-height: 90px;
  font-family: ${typography.type.code};
  font-size: ${typography.size.code}px;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    width: 90%;
    font-size: 72px !important;
    line-height: 72px;
    margin-bottom: 0;
  }

  @media (max-width: 650px) {
    margin-bottom: 32px;
    width: 100%;
    line-height: 62px;
    font-size: 62px !important;
  }
`;
