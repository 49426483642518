import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";

export const BannerBody = ({ data, hasReview }) => {
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapperSectionTop hasReview={hasReview}>
        <WrapperGeneral>
          <WrapperContent>
            <BoxTitle tag="h3" color={color.primary.white}>
              <RichText
                render={data?.raw}
                htmlSerializer={htmlSerializer}
              />
            </BoxTitle>
          </WrapperContent>
        </WrapperGeneral>
      </WrapperSectionTop>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  background-color: ${color.primary.white};
`;
const WrapperGeneral = styled.div`
  width: 100%;
  padding: 0 131px;
  top: 0;
  left: 0;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0 30px;
  }
  @media (max-width: 649px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;
const WrapperSectionTop = styled.div`
  background: ${props => props.hasReview ? "linear-gradient(to top, #fff 50%, #f4f6f8 50%)" : "#fff"};
`;
const WrapperContent = styled.div`
  display: flex;
  background-color: ${color.primary.blue};
`;
const BoxTitle = styled(Text)`
  width: 750px;
  margin: 0 auto;

  padding: 58px 0;
  text-align: center;
  font-weight: ${typography.weight.regular1};
  line-height: 56px;
  font-family: ${typography.type.code};
  font-size: ${typography.size.l3}px;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    width: 80%;
    padding: 40px;
    span {
      font-size: 56px !important;
      line-height: 56px;
      font-weight: 500;
    }
  }

  @media (max-width: 376px) {
    width: 90%;
    text-align: left;
    padding: 65px 20px;
  }
`;
