import React from "react";
import styled from "styled-components";
import { Banner } from "./Banner";
import { HowItWork } from "./HowItWork";
import { Benefit } from "./Benefit";
import { Comunity } from "./Comunity";
import { BannerBody } from "./BannerBody";
import { BannerBottom } from "./BannerBottom";
import { UpComming } from "./UpComming";
import { Events } from "./Events";
import { About } from "./About";

export const DenContainer = ({ data, dataEvents }) => {
  return (
    <WrapperLayout>
      <Banner
        den_logo={data?.den_logo?.url}
        banner={data?.banner?.url}
        title={data?.banner_title?.text}
        description={data?.banner_description?.text}
        button_label={data?.banner_button_label?.text}
        button_link={data?.banner_button_link}
      />
      <Benefit data={data?.body[0] === undefined ? {} : data?.body[0]} />
      <HowItWork data={data?.body1[0] === undefined ? {} : data?.body1[0]} />
      <Comunity data={data?.body2[0] === undefined ? {} : data?.body2[0]} />
      <About data={data?.body3[0] === undefined ? {} : data?.body3[0]} />
      <BannerBody
        data={data?.sub_mission_description}
        hasReview={data?.body3[0]?.items}
      />
      <UpComming data={data?.body5[0] === undefined ? {} : data?.body5[0]} />
      <Events data={dataEvents} />
      <BannerBottom
        title={data?.blurb}
        button_label={data?.blurb_button_label}
        button_link={data?.blurb_button_link}
      />
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
`;
